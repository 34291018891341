import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';

import SEO from 'src/components/SEO';
import AboutPage from 'src/components/pages/AboutPage';

const About = ({ data }) => {
  const { frontmatter } = data.markdownRemark;
  return (
    <>
      <SEO title={frontmatter.title} />
      <AboutPage
        intro={frontmatter.introduction}
        teamMembers={frontmatter.teamMembers}
      />
    </>
  );
};

About.propTypes = {
  data: PropTypes.object.isRequired
};

export default About;

export const query = graphql`
  query AboutPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "about-page" } }) {
      frontmatter {
        title
        introduction {
          title
          heading
          text
        }
        teamMembers {
          name
          title
          image {
            imgSrc {
              childImageSharp {
                gatsbyImageData(width: 200, placeholder: BLURRED)
              }
            }
            alt
          }
        }
      }
    }
  }
`;
