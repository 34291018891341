import React from 'react';
import PropTypes from 'prop-types';
import tw from 'twin.macro';

import Heading from 'src/components/Heading';
import Image from 'src/components/Image';

const Intro = tw.section`mt-24 text-center px-4 space-y-8`;
const IntroTitle = tw.p`text-xl font-bold text-navy-light`;
const Paragraph = tw.p`mx-auto sm:w-140`;

const Team = tw.section`mt-32 mb-72 mx-auto px-4 grid gap-y-8 xs:gap-y-12 xs: gap-x-5 sm:gap-y-24 sm:gap-x-11 grid-cols-1 xs:grid-cols-2 sm:grid-cols-3 lg:w-240`;
const Member = tw.div`text-navy-light flex flex-col items-center text-center`;
const ProfilePicture = tw(Image)`mb-6`;
const Name = tw.p`text-2xl mb-1`;
const Title = tw.p`text-sm`;

const AboutPage = ({ intro, teamMembers }) => (
  <>
    <Intro>
      <IntroTitle>{intro.title}</IntroTitle>
      <Heading>{intro.heading}</Heading>
      <Paragraph>{intro.text}</Paragraph>
    </Intro>
    <Team>
      {teamMembers.map(({ name, title, image }, index) => (
          <Member key={`${name}${title}${index}`}>
            <ProfilePicture src={image.imgSrc} alt={image.alt} />
            <Name>{name}</Name>
            <Title>{title}</Title>
          </Member>
        ))}
    </Team>
  </>
);

AboutPage.propTypes = {
  intro: PropTypes.object.isRequired,
  teamMembers: PropTypes.array.isRequired
};

export default AboutPage;
